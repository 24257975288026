@import '@taiga-ui/core/styles/taiga-ui-local';

:root {
  // Fonts
  --tui-heading-font: var(--m4p-font-family);
  --tui-text-font: var(--m4p-font-family);

  --tui-font-text: var(--tui-text-font);
  --tui-font-text-xl: normal var(--m4p-fs-xl)/var(--m4p-lh-xl) var(--tui-font-text);
  --tui-font-text-l: normal var(--m4p-fs-l)/var(--m4p-lh-l) var(--tui-font-text);
  --tui-font-text-l-2: normal var(--m4p-fs-l)/var(--m4p-lh-l-2) var(--tui-font-text);
  --tui-font-text-m: normal var(--m4p-fs-m)/var(--m4p-lh-m) var(--tui-font-text);
  --tui-font-text-m-2: normal var(--m4p-fs-m)/var(--m4p-lh-m-2) var(--tui-font-text);
  --tui-font-text-s: normal var(--m4p-fs-s)/var(--m4p-lh-s) var(--tui-font-text);
  --tui-font-text-s-2: normal var(--m4p-fs-s)/var(--m4p-lh-s-2) var(--tui-font-text);
  --tui-font-text-xs: normal var(--m4p-fs-xs)/var(--m4p-lh-xs) var(--tui-font-text);

  // Colors
  --tui-primary: var(--m4p-primary-500);
  --tui-primary-hover: var(--m4p-primary-700);
  --tui-primary-active: var(--m4p-primary-400);
  --tui-primary-text: var(--m4p-primary-contrast-500);
  --tui-secondary: var(--m4p-secondary-500);
  --tui-secondary-hover: var(--m4p-secondary-500);
  --tui-secondary-hover-contrast: var(--m4p-secondary-contrast-500);
  --tui-secondary-active: var(--m4p-secondary-200);

  --tui-accent: var(--m4p-accent-500);
  --tui-accent-hover: var(--m4p-accent-700);
  --tui-accent-active: var(--map-accent-400);
  --tui-accent-text: var(--m4p-accent-contrast-500);

  // Link
  --tui-link: var(--m4p-primary-400);
  --tui-link-hover: var(--m4p-secondary-400);

  // Selection
  --tui-selection: var(--m4p-primary-500);

  // Alerts
  --tui-success-fill: var(--m4p-success-600);
  --tui-success-bg: var(--m4p-success-100);
  --tui-success-bg-hover: var(--map-success-200);
  --tui-info-fill: var(--m4p-info-600);
  --tui-info-bg: var(--m4p-info-100);
  --tui-info-bg-hover: var(--map-info-200);
  --tui-error-fill: var(--m4p-danger-600);
  --tui-error-bg: var(--m4p-danger-50);
  --tui-error-bg-hover: var(--m4p-danger-100);
  --tui-warning-fill: var(--m4p-warn-900);
  --tui-warning-bg: var(--m4p-warn-100);
  --tui-warning-bg-hover: var(--m4p-warn-200);

  // Positive - Negative
  --tui-positive: var(--m4p-success-400);
  --tui-positive-hover: var(--m4p-success-200);
  --tui-negative: var(--m4p-danger-400);
  --tui-negative-hover: var(--m4p-danger-200);

  // Avatar
  --tui-avatar-border: var(--m4p-secondary-100);
  --tui-avatar-color: var(--m4p-secondary-contrast-400);
  --tui-avatar-background: var(--m4p-secondary-400);


}

tui-dialog[data-appearance='quick-add'] {
  margin: 1.25rem auto;
  min-width: 75vw;
}
tui-dialog[data-appearance='quick-view'] {

  margin: 1.25rem auto;
  min-width: 75vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: none!important;
  &::after {
    box-shadow: none!important;
    section {
      width: 100%;
    }
  }
  .t-content {
    width: 100%;
    background: transparent;
    box-shadow: none;
  }
}

[tuiWrapper][data-appearance='textfield'] {
  box-shadow: 0 0.1rem 0.1rem rgba(92, 92, 92, 0.09);

  &:hover:not(._no-hover) {
    box-shadow: 0 0.125rem 0.325rem rgba(19, 19, 19, 0.12);
  }
}

.t-cell[data-state="hover"] {
  color: var(--tui-secondary-hover-contrast);
}
.t-cell[data-state="hover"]:hover:not([data-range]) > .t-item {

  color: var(--tui-secondary-hover-contrast);
}

[tuiWrapper][data-appearance='danger'] {
  background: var(--m4p-danger-500);
  color: var(--m4p-danger-contrast-500);

  &:hover:not(._no-hover) {
    background: var(--m4p-danger-700);
  }

  &:active:not(._no-hover) {
    background: var(--m4p-danger-200);
  }
}
