html, body {
  height: 100%;
}

body {
  margin: 0;
}

body.ch-def-font {
  font: var(--tui-font-text-m);
}

a {
  text-decoration: none;
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected, .jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:focus, .jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:hover {
  color: var(--m4p-primary-contrast-500);
  background-color: var(--m4p-primary-500);
}

.jsoneditor-menu li button.jsoneditor-selected:hover {
  color: var(--m4p-accent-contrast-500) !important;
  background-color: var(--m4p-accent-500) !important;
}

.jsoneditor {
  border: thin solid var(--m4p-primary-500);
  border-radius: 0.25rem;
}

.jsoneditor-menu {
  color: var(--m4p-primary-contrast-500);
  background-color: var(--m4p-primary-500);
  border-bottom: 1px solid var(--m4p-primary-700);
}
