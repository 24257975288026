@use "sass:map";
@import "palette";
@import "_config";

:root {
  --m4p-font-family: #{$m4p-font};
  --m4p-font-weight-lighter: #{$m4p-font-weight-lighter};
  --m4p-font-weight-light: #{$m4p-font-weight-light};
  --m4p-font-weight-normal: #{$m4p-font-weight-normal};
  --m4p-font-weight-bold: #{$m4p-font-weight-bold};
  --m4p-font-weight-bolder: #{$m4p-font-weight-bolder};
  --m4p-font-size-base: #{$m4p-font-size-base};
  --m4p-line-height-base: #{$m4p-line-height-base};
  --m4p-line-height-sm: #{$m4p-line-height-sm};
  --m4p-line-height-lg: #{$m4p-line-height-lg};
  --m4p-fs-xs: #{$m4p-fs-xs};
  --m4p-lh-xs: #{$m4p-lh-xs};
  --m4p-fs-s: #{$m4p-fs-s};
  --m4p-lh-s: #{$m4p-lh-s};
  --m4p-lh-s-2: #{$m4p-lh-s-2};
  --m4p-fs-m: #{$m4p-fs-m};
  --m4p-lh-m: #{$m4p-lh-m};
  --m4p-lh-m-2: #{$m4p-lh-m-2};
  --m4p-fs-l: #{$m4p-fs-l};
  --m4p-lh-l: #{$m4p-lh-l};
  --m4p-lh-l-2: #{$m4p-lh-l-2};
  --m4p-fs-xl: #{$m4p-fs-xl};
  --m4p-lh-xl: #{$m4p-lh-xl};

  @each $name, $val in $my_primary_palette {
    @if $name != contrast {
      --m4p-primary-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-primary-contrast-#{$cname}: #{$cval}
      }
    }
  }
  @each $name, $val in $my_secondary_palette {
    @if $name != contrast {
      --m4p-secondary-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-secondary-contrast-#{$cname}: #{$cval}
      }
    }
  }
  @each $name, $val in $my_accent_palette {
    @if $name != contrast {
      --m4p-accent-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-accent-contrast-#{$cname}: #{$cval}
      }
    }
  }
  @each $name, $val in $my_warn_palette {
    @if $name != contrast {
      --m4p-warn-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-warn-contrast-#{$cname}: #{$cval}
      }
    }
  }
  @each $name, $val in $my_danger_palette {
    @if $name != contrast {
      --m4p-danger-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-danger-contrast-#{$cname}: #{$cval}
      }
    }
  }
  @each $name, $val in $my_success_palette {
    @if $name != contrast {
      --m4p-success-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-success-contrast-#{$cname}: #{$cval}
      }
    }
  }
  @each $name, $val in $my_info_palette {
    @if $name != contrast {
      --m4p-info-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-info-contrast-#{$cname}: #{$cval}
      }
    }
  }
  @each $name, $val in $my_dark_palette {
    @if $name != contrast {
      --m4p-dark-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-dark-contrast-#{$cname}: #{$cval}
      }
    }
  }
  @each $name, $val in $my_light_palette {
    @if $name != contrast {
      --m4p-light-#{$name}: #{$val};
    } @else {
      @each $cname, $cval in $val {
        --m4p-light-contrast-#{$cname}: #{$cval}
      }
    }
  }
}

//Generate some css buttons
//

.mbtn {
  all: unset;
  padding: .75rem 1.25rem;
  border-radius: 6px;
  font-size: 1rem;
  transition: all .4s ease-in-out;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;

  &:disabled {
    opacity: .5;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -50%;
    left: -30%;
    width: 0;
    height: 0;
    border-radius: 66%;
    transition: all .4s ease-in-out;
    z-index: -1;
  }

  &:active:before {
    opacity: .5;
    transition: 0s;
  }
}

.mbtn.mbtn-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mbtn.mbtn-large {
  padding: 4vmin 8vmin;
  font-size: 1.2rem;
}

.mbtn > * {
  margin-right: 10px;
}

$pp_contrast: map.get($my_primary_palette, contrast);
$ap_contrast: map.get($my_accent_palette, contrast);
$sp_contrast: map.get($my_secondary_palette, contrast);
$wp_contrast: map.get($my_warn_palette, contrast);
$dp_contrast: map.get($my_danger_palette, contrast);
$sup_contrast: map.get($my_success_palette, contrast);
$ip_contrast: map.get($my_info_palette, contrast);

@each $name, $val in $my_primary_palette {
  @if $name != contrast {
    .mbtn.primary-#{$name} {
      color: map.get($pp_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: darken($val, 8%);
      }

      &:hover {
        color: map.get($pp_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_primary_palette {
  @if $name != contrast {
    .mbtn.primary-outline-#{$name} {
      color: $val;
      border: 1px solid $val;
      background: transparent;

      &:after {
        background-color: transparent;
        border: none;
      }

      &:before {
        background-color: $val;
        border: none;
      }

      &:hover {
        color: map.get($pp_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }

      &:active:before {
        opacity: .8;
      }
    }
  }
}

@each $name, $val in $my_accent_palette {
  @if $name != contrast {
    .mbtn.accent-#{$name} {
      color: map.get($ap_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: darken($val, 8%);
      }

      &:hover {
        color: map.get($ap_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_accent_palette {
  @if $name != contrast {
    .mbtn.accent-outline-#{$name} {
      color: $val;
      border: 1px solid $val;
      background: transparent;

      &:after {
        background-color: transparent;
        border: none;
      }

      &:before {
        background-color: $val;
        border: none;
      }

      &:hover {
        color: map.get($ap_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }

      &:active:before {
        opacity: .8;
      }
    }
  }
}

@each $name, $val in $my_secondary_palette {
  @if $name != contrast {
    .mbtn.secondary-#{$name} {
      color: map.get($sp_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: darken($val, 8%);
      }

      &:hover {
        color: map.get($sp_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_secondary_palette {
  @if $name != contrast {
    .mbtn.secondary-outline-#{$name} {
      color: $val;
      border: 1px solid $val;
      background: transparent;

      &:after {
        background-color: transparent;
        border: none;
      }

      &:before {
        background-color: $val;
        border: none;
      }

      &:hover {
        color: map.get($sp_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }

      &:active:before {
        opacity: .8;
      }
    }
  }
}

@each $name, $val in $my_warn_palette {
  @if $name != contrast {
    .mbtn.warn-#{$name} {
      color: map.get($wp_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: darken($val, 8%);
      }

      &:hover {
        color: map.get($wp_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_warn_palette {
  @if $name != contrast {
    .mbtn.warn-outline-#{$name} {
      color: $val;
      border: 1px solid $val;
      background: transparent;

      &:after {
        background-color: transparent;
        border: none;
      }

      &:before {
        background-color: $val;
        border: none;
      }

      &:hover {
        color: map.get($wp_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }

      &:active:before {
        opacity: .8;
      }
    }
  }
}

@each $name, $val in $my_danger_palette {
  @if $name != contrast {
    .mbtn.danger-#{$name} {
      color: map.get($dp_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: darken($val, 8%);
      }

      &:hover {
        color: map.get($dp_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_danger_palette {
  @if $name != contrast {
    .mbtn.danger-outline-#{$name} {
      color: $val;
      border: 1px solid $val;
      background: transparent;

      &:after {
        background-color: transparent;
        border: none;
      }

      &:before {
        background-color: $val;
        border: none;
      }

      &:hover {
        color: map.get($dp_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }

      &:active:before {
        opacity: .8;
      }
    }
  }
}

@each $name, $val in $my_success_palette {
  @if $name != contrast {
    .mbtn.success-#{$name} {
      color: map.get($sup_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: darken($val, 8%);
      }

      &:hover {
        color: map.get($sup_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_success_palette {
  @if $name != contrast {
    .mbtn.success-outline-#{$name} {
      color: $val;
      border: 1px solid $val;
      background: transparent;

      &:after {
        background-color: transparent;
        border: none;
      }

      &:before {
        background-color: $val;
        border: none;
      }

      &:hover {
        color: map.get($sup_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }

      &:active:before {
        opacity: .8;
      }
    }
  }
}

@each $name, $val in $my_info_palette {
  @if $name != contrast {
    .mbtn.info-#{$name} {
      color: map.get($ip_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: darken($val, 8%);
      }

      &:hover {
        color: map.get($ip_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_info_palette {
  @if $name != contrast {
    .mbtn.info-outline-#{$name} {
      color: $val;
      border: 1px solid $val;
      background: transparent;

      &:after {
        background-color: transparent;
        border: none;
      }

      &:before {
        background-color: $val;
        border: none;
      }

      &:hover {
        color: map.get($ip_contrast, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }

      &:active:before {
        opacity: .8;
      }
    }
  }
}

// Weird buttons now -->
@each $name, $val in $my_primary_palette {
  @if $name != contrast {
    .mbtn.primary-accent-#{$name} {
      color: map.get($pp_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: map.get($my_accent_palette, $name)
      }

      &:hover {
        color: map.get($ap_contrast, $name);
        border: 1px solid map.get($my_accent_palette, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_accent_palette {
  @if $name != contrast {
    .mbtn.accent-primary-#{$name} {
      color: map.get($ap_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: map.get($my_primary_palette, $name)
      }

      &:hover {
        color: map.get($pp_contrast, $name);
        border: 1px solid map.get($my_primary_palette, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}

@each $name, $val in $my_warn_palette {
  @if $name != contrast {
    .mbtn.warn-primary-#{$name} {
      color: map.get($wp_contrast, $name);
      //border: 1px solid $val;
      border-color: $val;

      &:after {
        background-color: $val;
      }

      &:before {
        background-color: map.get($my_primary_palette, $name)
      }

      &:hover {
        color: map.get($pp_contrast, $name);
        border: 1px solid map.get($my_primary_palette, $name);

        &:before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}
