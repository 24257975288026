@use "sass:map";
@import "palette";
@import "_config";

$primary: map.get($my_primary_palette, 500);
$secondary: map.get($my_secondary_palette, 500);
$success: map.get($my_success_palette, 500);
$danger: map.get($my_danger_palette, 500);
$warning: map.get($my_warn_palette, 500);
$info: map.get($my_info_palette, 500);
$dark: map.get($my_dark_palette, 500);
$light: map.get($my_light_palette, 500);
$font-family-base: #{$m4p-font};
$font-size-base: $m4p-font-size-base;
$font-weight-base: #{$m4p-font-weight-normal};
$font-weight-lighter: #{$m4p-font-weight-lighter};
$font-weight-light: #{$m4p-font-weight-light};
$font-weight-normal: #{$m4p-font-weight-normal};
$font-weight-bold: #{$m4p-font-weight-bold};
$font-weight-bolder: #{$m4p-font-weight-bolder};
$line-height-base: $m4p-line-height-base;
$line-height-sm: $m4p-line-height-sm;
$line-height-lg: $m4p-line-height-lg;
$min-contrast-ratio: 2;


// Configuration
@import "functions";
@import "variables";
@import "variables-dark";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "helpers";
@import "utilities/api";

